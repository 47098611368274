body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden !important;
}

input::-webkit-credentials-auto-fill-button {
  visibility: hidden !important;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none !important;
}

code {
  font-family: 'Montserrat', sans-serif;
}

*,
:before,
:after {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.outerWrapperSmall {
  width: 100% !important;
  min-width: unset !important;
}

.videoBlock {
  width: 100%;
  height: 100%;
}

/* @media (min-width: 550px) and (max-width: 768px) {
  .videoBlock {
    height: 250px;
  }
}

@media (min-width: 389px) and (max-width: 550px) {
  .videoBlock {
    height: 185px;
  }
} */

@media (max-width: 768px) {
  .videoBlock {
    height: 500px;
  }
}
